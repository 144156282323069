.OrdersOrderStatus {
  position: relative;
  background-color: white;
  margin: 16px 0;
  padding: 12px;
  border-left-style: solid;
  border-left-color: #30de00;
  border-left-width: 8px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);

  &-title {
    font-size: 24px;
    color: gray;
  }

  &-value {
    font-size: 24px;
  }
}