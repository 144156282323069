.ContentPage {
  background: whitesmoke;

  @media (max-width: 1110px) {
    width: 65vw;
  }

  &-container {
    padding: 12px 12px 0;
  }
}
