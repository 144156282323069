.DeliveryItem {
  position: relative;
  background-color: white;
  margin: 6px 0 6px 6px;
  padding: 12px;
  border-left-style: solid;
  border-left-color: #30de00;
  border-left-width: 8px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;

  &-toggle {
    cursor: pointer;
  }

  &-suppress-card-header {
    padding: 0 !important;
    margin: 0 !important;
    background-color: white !important;
    border-bottom: 0 !important;
  }

  &-toggle-icon {
    position: absolute;
    right: 25px;
    top: 25px;
  }

  &-inactive-icon {
    color: lightgray;
  }

  &-active-icon {
    color: #30de00;
  }

  &-canceled-icon {
    color: #8d3a3a;
  }
}
