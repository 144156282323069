.InstallmentItem {
  position: relative;
  background-color: white;
  margin: 8px 0 8px 8px;
  padding: 4px 12px;
  //border-left-style: solid;
  //border-left-color: #30de00;
  //border-left-width: 8px;
  //box-shadow: 2px 2px 5px rgba(0.5, 0.5, 0.1, 0.1);
  //border-style: solid;
  //border-color: lightgrey;
  //border-width: 1px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}
