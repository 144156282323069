.DeliveryOrders {
  position: relative;
  max-height: calc(100vh - 110px);

  ///* Track */
  //::-webkit-scrollbar-track {
  //  border-radius: 0;
  //}

  ///* Handle */
  //::-webkit-scrollbar-thumb {
  //  background: rgba(129, 129, 129, 0.3);
  //  border-radius: 10px;
  //}
  //
  //::-webkit-scrollbar-thumb:window-inactive {
  //  background: rgba(129, 129, 129, 0.3);
  //}
  //
  //::-webkit-scrollbar {
  //  height: 10px;
  //  width: 5px;
  //  border-bottom: 7px solid rgba(0, 0, 0, 0);
  //  border-top: 7px solid rgba(0, 0, 0, 0);
  //  background-clip: padding-box;
  //  -webkit-border-radius: 8px;
  //}

  &-divider {
    background-color: rgba(0, 0, 0, 0.1);
    height: 1px;
    margin: 5px 12px 5px 0;
  }

  &-container {
    position: relative;
    max-height: 60vh;
    overflow-x: hidden;
    overflow-y: auto;
  }
}