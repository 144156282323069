.OrdersOrderItem {
  padding: 6px 12px 0 2px;
  height: 100%;
  width: 100%;

  &-subtitle {
    font-size: 12px;
    color: gray;

    &-right {
      font-size: 12px;
      color: gray;
      text-align: right;
    }
  }
}