.select-wrapper-container{
  position: relative;

  .select-wrapper{
    position: relative;
    //z-index: 1;
  }

  .input-required{
    position: absolute;
    border: 0;
    color: transparent;
    background-color: transparent;
    margin-left: auto;
    margin-right: auto;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 0;
    opacity: 0;
  }
}

.small-select-wrapper {
  @media (max-width: 500px) {
    padding: 2px !important;
  }
}