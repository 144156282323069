.Login {
  background-color: #faf9f9;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  &-container {
    height: 80%;
    width: 70%;
    background-color: #fcfcfc;
    box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.1);
    padding: 16px;
    display: flex;
  }

  &-info {
    width: 100%;
    float: left;
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-login {
    width: 100%;
    float: right;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-login-form {
    width: 100%;
  }

  &-login-button {
    margin: 12px 0;
    width: 100%;
  }

  &-info-subtitle {
    margin: 12px 0;
    text-align: center;
    font-size: 24px;
  }

  &-info-text {
    text-align: center;
  }

  &-logo {
    width: 156px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  &-divider-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-divider {
    height: 70%;
    width: 1px;
    background-color: lightgray;
    margin: 32px;
  }
}