.ExpensesContentFilter {
  position: relative;
  background-color: white;
  margin: 4px 0 4px 4px;
  padding: 12px;
  border-left-style: solid;
  border-left-color: #007ede;
  border-left-width: 8px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);

&-toggle {
     cursor: pointer;
 }

&-suppress-card-header {
     padding: 0 !important;
     margin: 0 !important;
     background-color: white !important;
     border-bottom: 0 !important;
 }

&-toggle-icon {
     position: absolute;
     right: 25px;
     top: 25px;
 }

&-inative-icon {
     color: lightgray;
 }

&-active-icon {
     color: #30de00;
 }
}

.card-body {
  @media (max-width: 500px) {
    padding: 2px !important;
  }
}

.small-col {
  @media (max-width: 500px) {
    padding: 2px !important;
  }
}

.text-field {
  @media (max-width: 500px) {
    padding: 2px 6px !important;
    height: 28px !important;
  }
}

.small-btn {
  @media (max-width: 500px) {
    padding: 2px !important;
  }
}