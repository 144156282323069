.OrdersCreateOrder {
  &-order-items {
    background-color: white;
    border-left-style: solid;
    border-left-color: #30de00;
    border-left-width: 8px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    margin: 16px 0;
    padding: 8px 16px 16px 16px;
  }
}