.small-btn {
    @media (max-width: 500px) {
        padding: 2px !important;
    }
}

.small-title {
    @media (max-width: 500px) {
        font-size: 20px !important;
    }
}